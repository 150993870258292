import t from 'translations/v2/reusable/newsletterSignup'

export type ValidationRuleTypes = {
  required?: boolean
  maxLength?: number
  pattern?: RegExp
}

type FormDataTypes = {
  value?: string
  errors?: ErrorType[]
  rules?: ValidationRuleTypes
}

export type ErrorType = 'noValue' | 'tooLong' | 'invalidPattern'

export type NewsletterDataType = {
  firstName?: FormDataTypes
  lastName?: FormDataTypes
  country?: FormDataTypes
  email?: FormDataTypes
}

export const getCountryOptions = (
  countries: any,
  locale: string,
  location: any
) => {
  const mailChimpCountries = countries?.filter(
    (country: any) => country.mailchimpListId
  )

  const selectedCountry = mailChimpCountries?.find(
    (country: any) => location?.countryCode?.toLowerCase() === country.shortcode
  )
  const countryOptions = [
    ...(selectedCountry
      ? [
          {
            label: selectedCountry.name,
            value: selectedCountry.mailchimpListId,
          },
        ]
      : [
          {
            label: `${t.country[locale]}`,
            value: 'none',
            disabled: true,
          },
        ]),
    ...mailChimpCountries
      .filter((country: any) => !selectedCountry || country !== selectedCountry)
      .map((country: any) => ({
        label: country.name,
        value: country.mailchimpListId,
      })),
  ]

  return countryOptions
}

export const validateFormField = (
  rules: ValidationRuleTypes,
  value: string
) => {
  const errors: ErrorType[] = []
  Object.keys(rules).forEach((rule) => {
    switch (rule) {
      case 'required':
        rules[rule] && !value && errors.push('noValue')
        break
      case 'maxLength':
        String(value).length > rules[rule] && errors.push('tooLong')
        break
      case 'pattern':
        const re = new RegExp(rules[rule])
        !re.test(String(value)) && errors.push('invalidPattern')
        break
      default:
        break
    }
  })
  return errors
}

export const isError = (data: NewsletterDataType) => {
  let error = false
  Object.keys(data).forEach((item) => {
    if (data[item].errors.length > 0) {
      error = true
    }
  })
  return error
}

export const validateForm = (formData: NewsletterDataType) => {
  const mutateFormData = { ...formData }
  Object.keys(mutateFormData).forEach((field) => {
    mutateFormData[field].errors = validateFormField(
      mutateFormData[field].rules,
      mutateFormData[field].value
    )
  })
  return mutateFormData
}

export const getDefaultCountry = (countries: any, location: any): string => {
  const defaultCountry = countries?.find((country: any) => {
    return (
      country.mailchimpListId &&
      country.shortcode == location?.countryCode.toLowerCase()
    )
  })
  return defaultCountry?.mailchimpListId || 'none'
}

export const getDefaultNewsletterData = (defaultCountry: string) => {
  return {
    firstName: {
      value: '',
      errors: [],
      rules: { required: true, maxLength: 50 },
    },
    lastName: {
      value: '',
      errors: [],
      rules: { required: true, maxLength: 50 },
    },
    country: {
      value: defaultCountry,
      errors: [],
      rules: { required: true, maxLength: 50 },
    },
    email: {
      value: '',
      errors: [],
      rules: {
        required: true,
        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        maxLength: 50,
      },
    },
  }
}
