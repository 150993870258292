'use client'

import axios from 'axios'
import Link from 'next/link'
import { useEffect, useState } from 'react'

import t from 'translations/v2/reusable/newsletterSignup'

import Column from '@layout/Column/Column'
import Button from '@library/Button/Button'
import Wrapper from '@layout/Wrapper/Wrapper'
import SmallText from '@library/Texts/Small/Small'
import InputText from '@library/Inputs/Input/Input'
import ColumnContainer from '@layout/Column/Container/Container'
import ContentWrapper from '@layout/ContentWrapper/ContentWrapper'
import SelectDropdown from '@library/Inputs/SelectDropdown/SelectDropdown'

import {
  getCountryOptions,
  getDefaultCountry,
  getDefaultNewsletterData,
  isError,
  NewsletterDataType,
  validateForm,
  validateFormField,
  ValidationRuleTypes,
} from './Newsletter.functions'

import styles from './Newsletter.module.css'

export default function Newsletter({ locale, countries, location }) {
  const [formData, setFormData] = useState(
    getDefaultNewsletterData(getDefaultCountry(countries, location))
  )
  const [btnText, setBtnText] = useState(t.subscribe[locale])
  const [apiResponse, setApiResponse] = useState(null)

  useEffect(() => {
    const defaultCountry = getDefaultCountry(countries, location)
    setFormData(getDefaultNewsletterData(defaultCountry))
  }, [countries, location])

  const handleSubmit = (event: any) => {
    if (event) {
      event.preventDefault()
      const validatedData = validateForm(formData)
      // @ts-ignore
      setFormData(validatedData)
      if (!isError(validatedData)) {
        subscribe(validatedData, locale)
      }
    }
  }

  const subscribe = async (data: NewsletterDataType, locale) => {
    const dataValues = {
      tags: ['NEWSLETTER', `LANG_${locale.toUpperCase()}`],
      email: data.email.value,
      listId: data.country.value,
      firstName: data.firstName.value,
      lastName: data.lastName.value,
    }
    try {
      await axios.post('/api/subscribe', {
        data: { ...dataValues },
      })

      setFormData(
        getDefaultNewsletterData(getDefaultCountry(countries, location))
      )
      setBtnText(t.successSubscribe[locale])
      setApiResponse('success')
    } catch (error) {
      setBtnText(t.errorSubscribe[locale])
      setApiResponse('error')
    }
  }

  const handleFieldValidation = (
    fieldName: string,
    value: string,
    rules: ValidationRuleTypes
  ) => {
    const errors = validateFormField(rules, value)
    const updatedFormData = { ...formData }
    updatedFormData[fieldName].value = value
    updatedFormData[fieldName].errors = errors
    return updatedFormData
  }

  const handleSelect = (
    fieldName: string,
    value: string,
    rules: ValidationRuleTypes
  ) => {
    setFormData(handleFieldValidation(fieldName, value, rules))
  }

  const handleChange = (
    fieldName: string,
    value: string,
    rules: ValidationRuleTypes
  ) => {
    setFormData(handleFieldValidation(fieldName, value, rules))
  }

  return (
    <Wrapper className={styles.wrapper}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <ContentWrapper>
          <h2 className={styles.h2}>{t.title[locale]}</h2>
          <p className={styles.p}>{t.p[locale]}</p>
        </ContentWrapper>
        <ColumnContainer>
          <Column width={50}>
            <ContentWrapper margin="20px 4px 4px 4px">
              <InputText
                name="firstName"
                placeholder={t.firstName[locale]}
                value={formData.firstName.value}
                errors={formData.firstName.errors}
                onChange={(value) =>
                  handleChange('firstName', value, formData.firstName.rules)
                }
              />
            </ContentWrapper>
          </Column>
          <Column width={50}>
            <ContentWrapper margin="20px 4px 4px 4px">
              <InputText
                name="lastName"
                placeholder={t.lastName[locale]}
                value={formData.lastName.value}
                errors={formData.lastName.errors}
                onChange={(value) =>
                  handleChange('lastName', value, formData.lastName.rules)
                }
              />
            </ContentWrapper>
          </Column>
        </ColumnContainer>
        <ColumnContainer>
          <Column width={33}>
            <ContentWrapper margin="20px 4px 4px 4px">
              <SelectDropdown
                value={formData.country.value}
                errors={formData.country.errors}
                options={
                  countries && getCountryOptions(countries, locale, location)
                }
                onSelect={(event) =>
                  handleSelect(
                    'country',
                    event.target.value,
                    formData.country.rules
                  )
                }
              />
            </ContentWrapper>
          </Column>
          <Column width={66}>
            <ContentWrapper margin="20px 4px 4px 4px">
              <InputText
                name="email"
                placeholder={t.email[locale]}
                value={formData.email.value}
                errors={formData.email.errors}
                onChange={(value) =>
                  handleChange('email', value, formData.email.rules)
                }
              />
            </ContentWrapper>
          </Column>
        </ColumnContainer>
        <ColumnContainer>
          <Column>
            <ContentWrapper margin={'10px 4px'}>
              <SmallText className={styles.small}>
                {t.disclaimer[locale]}
                <SmallText className={styles.moreInfo}>
                  {t.moreInfo[locale]}
                  <Link href="https://www.kayakomat.com/privacy" passHref>
                    <div className={styles.link}>{t.privacy[locale]}</div>
                  </Link>
                </SmallText>
              </SmallText>
            </ContentWrapper>
          </Column>
        </ColumnContainer>
        <ColumnContainer>
          <Column>
            <Button type="submit" onClick={handleSubmit} style={apiResponse}>
              {btnText}
            </Button>
          </Column>
        </ColumnContainer>
      </form>
    </Wrapper>
  )
}
