import H3 from '@library/Texts/H3'
import P from '@library/Texts/Paragraph'
import Column from '@layout/Column/Column'
import Container from '@layout/Container/Container'
import OverlayIcon from '@library/Icons/Overlay/Overlay'
import ColumnContainer from '@layout/Column/Container/Container'
import ImageRoundCorners from '@library/Media/Image/RoundCorner/RoundCorner'

import t from 'translations/v2/reusable/video'
import VideoModal from './VideoModal/VideoModal'

export default function VideoHowToInfo({ locale }) {
  return (
    <Container>
      <ColumnContainer gap={50}>
        <Column width={50} vAlign="center">
          <H3>{t.title[locale]}</H3>
          <P>{t.text[locale]}</P>
        </Column>
        <Column width={50}>
          <VideoModal
            embed={
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/p8M_XMUEpp8?si=3sQd8xdJ2mza03oh"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            }
          >
            <OverlayIcon imgSrc="/icons/play-video.svg" maxWidth="120px">
              <ImageRoundCorners src="/images/KAYAKOMAT_Rental_Station_Video_Homepage.jpg" alt="A KAYAKOMAT automatic kayak rental station on a beach. A KAYAKOMAT operator shows a group of families how to put their kayaks into the water." />
            </OverlayIcon>
          </VideoModal>
        </Column>
      </ColumnContainer>
    </Container>
  )
}
