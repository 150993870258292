const t = {
  title: {
    sv: 'HÅLL KONTAKTEN OCH FÅ 10% PÅ DIN NÄSTA PADDLING!',
    en: 'STAY IN TOUCH AND GET 10% OFF YOUR NEXT PADDLE!',
    de: 'BLEIB IN KONTAKT UND ERHALTE 10% RABATT AUF DEINE NÄCHSTE BUCHUNG!',
    es: 'SUSCRIBETE Y RECIBE UN 10% DE DESCUENTO EN TU PRÓXIMA RESERVA',
    dk: 'HOLD KONTAKTEN OG FÅ 10% RABAT PÅ DIN NÆSTE PADLETUR!',
    fr: 'RESTEZ INFORMÉS ET OBTENEZ 10% DE RÉDUCTION SUR VOTRE PROCHAINE PAGAIE !',
  },
  p: {
    sv: 'För att få uppdateringar om nya platser, evenemang och kampanjer i ditt område, fyll i din e-postadress och land för de mest relevanta nyheterna.',
    en: 'To get updates about new locations, special events and promotions in your area, fill in your email address and country for the most relevant news.',
    de: 'Um Informationen zu neuen Standorten, speziellen Events und Aktionen in deiner Region zu erhalten, trage einfach deine E-Mail-Adresse und dein Land ein, um relevante Neuigkeiten zu erhalten.',
    es: 'Para recibir novedades sobre nuevas ubicaciones, eventos especiales y promociones en tu área, déjanos tu dirección de correo electrónico y país para recibir las noticias más relevantes.',
    dk: 'For at få opdateringer om nye lokationer, særlige begivenheder og kampagner i dit område, skal du udfylde din e-mailadresse og land for de mest relevante nyheder.',
    fr: 'Pour recevoir des mises à jour sur les nouveaux emplacements, les événements spéciaux et les promotions dans votre région, saisissez votre adresse e-mail et votre pays pour les actualités les plus pertinentes.',
  },
  disclaimer: {
    sv: 'Genom att fylla i detta forumlär godkänner du att vi skickar dig vårt nyhetsbrev samt att du accepterar vår sekretesspolicy. ',
    en: 'By submitting this form, you agree to receive our newsletter. We will not share yous data or use it for marketing. ',
    de: 'Durch das Absenden dieses Formulars stimmen Sie zu, unseren Newsletter zu erhalten. Wir werden Ihre Daten nicht weitergeben oder für Marketingzwecke verwenden. ',
    es: 'Al enviar este formulario, aceptas recibir nuestro boletín informativo. No compartiremos tus datos ni los utilizaremos con fines de marketing. ',
    dk: 'Ved at indsende denne formular, accepterer du at modtage vores nyhedsbrev. Vi deler ikke dine data eller bruger dem til markedsføring. ',
    fr: 'En complétant ce formulaire, vous acceptez de recevoir notre bulletin d’informations. Nous ne partagerons pas vos données et ne les utiliserons pas à des fins marketing. ',
  },
  moreInfo: {
    sv: 'Mer information: ',
    en: 'More information: ',
    de: 'Mehr Informationen: ',
    es: 'Más información: ',
    dk: 'Mere information: ',
    fr: 'Plus d’informations: ',
  },
  privacy: {
    sv: 'Sekretesspolicy',
    en: 'Privacy policy',
    de: 'Datenschutzbestimmungen',
    es: 'Política de privacidad',
    dk: 'Privatlivspolitik',
    fr: 'Politique de confidentialité',
  },
  country: {
    sv: 'Land',
    en: 'Country',
    de: 'Land',
    es: 'País',
    dk: 'Land',
    fr: 'Pays',
  },
  firstName: {
    sv: 'Förnamn',
    en: 'First Name',
    de: 'Vorname',
    es: 'Nombre',
    dk: 'Fornavn',
    fr: 'Prénom',
  },
  lastName: {
    sv: 'Efternamn',
    en: 'Last Name',
    de: 'Name',
    es: 'Apellido',
    dk: 'Efternavn',
    fr: 'Nom',
  },
  email: {
    sv: 'Email',
    en: 'Type your email here',
    de: 'E-Mail',
    es: 'Correo electrónico',
    dk: 'E-mail',
    fr: 'Courriel',
  },
  subscribe: {
    sv: 'PRENUMERERA',
    en: 'SUBSCRIBE',
    de: 'ABONNIEREN',
    es: 'SUBSCRIBE',
    dk: 'ABONNÈR',
    fr: 'S’ABONNER',
  },
  successSubscribe: {
    sv: 'Tack för anmälan!',
    en: 'Thanks for signing up!',
    de: 'Danke für deine Anmeldung!',
    es: 'SUCCESS - THANKS!',
    dk: 'Tak fordi du tilmeldte dig!',
    fr: 'Merci de vous être inscrit !',
  },
    errorSubscribe: {
    sv: 'Nåt gick fel, prova igen!',
    en: 'Something went wrong, try again!',
    de: 'Etwas ist schiefgelaufen, bitte versuch erneut!',
    es: 'ERROR - TRY AGAIN',
    dk: 'Noget gik galt, prøv igen!',
    fr: 'Un problème s\'est produit, réessayez !',

}
}

export default t
