import styles from './Heading.module.css'

type H1Types = {
  invert?: boolean
  className?: string
  children: string | JSX.Element
}

export default function H1({ children, className = '', invert }: H1Types) {
  return (
    <h1
      className={`${styles.h1} ${className}`}
      style={{ color: invert && '#FFF' }}
      dangerouslySetInnerHTML={{ __html: children }}
    ></h1>
  )
}
