module.exports = {
    "title": {
        sv: 'Världens ledande uthyrare av kajak och SUP - se hur det fungerar',
        en: 'The world’s leading automatic kayak & SUP rental - watch how it works',
        de: 'KAYAKOMAT ist weltweit führend in Self Service Mietstationen für Kajaks und SUP-Boards.',
        es: 'El líder mundial de alquiler automático de kayaks y SUP: mira cómo funciona',
        dk: 'Verdens førende automatiske kajak- og SUP-udlejning - se, hvordan det fungerer',
        fr: 'La location automatique de kayak et de paddle la plus réputée au monde - Découvrez comment fonctionne ' 
    }, 
    "text": {
        sv: 'KAYAKOMAT använder moderna och smarta lås för att du ska kunna hyra och paddla så enkelt som möjligt, även på avlägsna platser. Boka i förväg eller spontant på plats, och lås upp din utrustning när som helst på dygnet.',
        en: 'KAYAKOMAT uses the latest smart lock technology to offer a seamless rental experience, even in remote locations. Book in advance or spontaneously on site, and unlock your equipment at any time of the day. ',
        de: 'Schau, wie es funktioniert! Der KAYAKOMAT nutzt die modernste Smart-Lock Technologie, um dir ein reibungsloses Vermietungserlebnis zu bieten, selbst an abgelegenen Orten. Du hast die Wahl, ob du im Voraus buchst oder dich spontan vor Ort entscheidest – deine Ausrüstung kannst du jederzeit am Tag freischalten.',
        es: 'KAYAKOMAT utiliza la última tecnología de candados inteligentes para ofrecer una experiencia de alquiler sin problemas, incluso en ubicaciones remotas. Reserva con antelación o espontáneamente en el lugar y desbloquea tu equipo en cualquier momento del día.',
        dk: 'KAYAKOMAT bruger den seneste smarte låse-teknologi og tilbyder en problemfri lejeoplevelse, selv på fjerntliggende steder. Bestil på forhånd eller spontant på stedet, og lås dit udstyr op når som helst på dagen.',
        fr: 'KAYAKOMAT utilise la dernière technologie de verrouillage intelligent pour offrir une expérience de location fluide, même dans des endroits éloignés. Réservez à l\'avance ou spontanément sur place, et déverrouillez votre équipement à n\'importe quel moment de la journée.' 
    }, 
}